"use client";
import { useSite } from "@hooks/use-site";
import { redirectTo } from "@i18n";
import { LoginWrapper } from "@nilo/auth/components";
import { Platform } from "@nilo/codegen";
import Routes from "@routes";

interface Props {
  isConfirmationLink?: boolean;
  isForgotLink?: boolean;
  usernameByHash?: string;
  codeByHash?: string;
  showRegister?: boolean;
}

export const LoginContainer = (props: Props) => {
  const { site } = useSite();
  if (!site) return null;
  const urlHome = site?.recommendations ? Routes.suggestions : Routes.shopping;

  const loginSuccessHandler = () => {
    redirectTo(urlHome);
  };

  return (
    <LoginWrapper
      chatbotNumber={site?.conversationalChannel?.phoneNumber}
      codeByHash={props.codeByHash}
      isConfirmationLink={props.isConfirmationLink}
      isForgotLink={props.isForgotLink}
      loginSuccessCallback={loginSuccessHandler}
      usernameByHash={props.usernameByHash}
      platform={Platform.Pwa}
    />
  );
};
